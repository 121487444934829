import styled, { css } from 'styled-components'

import { pxToRem } from 'utils'

import { IProp } from './interfaces'

export const Container = styled.div<any>`
  position: relative;
`

export const Handler = styled.div<IProp>`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: ${pxToRem(16)};
  font-family: ${({ theme }) => theme.fontTypes.medium};

  width: 100%;
  height: ${pxToRem(48)};
  background-color: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.gray2 : theme.colors.primaryLight};
  color: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.darkGray : theme.colors.white};
  border-radius: ${pxToRem(29)};
  padding: ${pxToRem(15)} ${pxToRem(28)};
  cursor: pointer;

  position: relative;
  z-index: 2;

  ${({ centered }: any) =>
    centered &&
    css`
      justify-content: center;
    `}

  ${({ error }: any) =>
    error &&
    css`
      background: ${({ theme }) => theme.colors.red} 0% 0% no-repeat padding-box;
      border: solid 1px ${({ theme }) => theme.colors.red2};
    `}

  ${({ outColor }: any) =>
    outColor &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.cian};
    `}

  ${({ isPix, isOpen }) =>
    isPix &&
    isOpen &&
    css`
      @media ${({ theme }) => theme.mediaQueries.smUp} {
        top: 20px;
      }
    `}
`

export const IconWrapper = styled.div`
  position: absolute;

  right: ${pxToRem(20)};
  top: 50%;
  transform: translateY(-50%);
`

export const WrapperContent = styled.div<IProp>`
  background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 9px ${({ theme }) => theme.colors.black2};
  border-radius: ${pxToRem(33)};

  width: calc(100% + 40px);
  transform: translateX(-20px);
  position: absolute;
  top: ${pxToRem(-24)};

  ${({ isPix }) =>
    isPix &&
    css`
      top: ${pxToRem(-20)};
      @media ${({ theme }) => theme.mediaQueries.smUp} {
        margin-top: 20px;
      }
    `}

  padding: ${pxToRem(90)} ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(20)};

  margin-bottom: ${pxToRem(60)};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    box-shadow: unset;
  }
`

export const CardImage = styled.img<any>`
  width: ${pxToRem(45)};
  margin-right: ${pxToRem(10)};

  ${({ shouldIncrease }: any) =>
    shouldIncrease &&
    css`
      width: ${pxToRem(50)};
    `}
`

export const Label = styled.div`
  text-align: left;
  font-size: ${pxToRem(14)};
  font-family: ${({ theme }) => theme.fontTypes.book};
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.red2};
  position: absolute;
  bottom: ${pxToRem(-22)};
  left: ${pxToRem(22)};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    left: 0;
    right: 0;

    text-align: center;
  }
`

export const ContainerGrid = styled.div<any>``

export const Image = styled.img<any>``
