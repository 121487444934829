import styled, { css } from 'styled-components'
import { pxToRem } from 'utils'
import { Iprops } from '../PixForm/styles'

export const AlignChange = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${pxToRem(20)};

  ${({ somethingOpen }: any) =>
    somethingOpen &&
    css`
      margin-bottom: ${pxToRem(40)};
    `}

  ${({ shoulShrink }: any) =>
    shoulShrink &&
    css`
      margin-bottom: ${pxToRem(28)};
    `}

  :last-of-type {
    margin-top: ${pxToRem(45)};
  }

  a {
    color: ${({ theme }) => theme.colors.primaryLight};
  }
`

export const Legend = styled.div<any>`
  text-align: center;
  font-family: ${({ theme }) => theme.fontTypes.book};
  font-size: ${pxToRem(18)};
  color: ${({ theme }) => theme.colors.darkGray};
  opacity: 1;

  ${({ top }: any) =>
    top &&
    css`
      margin-top: ${pxToRem(40)};
    `}
`

export const AlignRequirements = styled.div`
  display: flex;
  margin-left: ${pxToRem(6)};
  margin-bottom: ${pxToRem(28)};
  padding-bottom: ${pxToRem(28)};

  border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray};
`

export const AcceptedCads = styled.div`
  margin-right: ${pxToRem(165)};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: ${pxToRem(52)};
  }
`

export const Flags = styled.div`
  display: flex;
  flex-direction: column;
`

export const AlignFlags = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${pxToRem(11)};
`

export const Ul = styled.ul`
  padding-left: ${pxToRem(10)};

  list-style: none;

  li {
    position: relative;

    ::before {
      position: absolute;
      top: 30%;
      left: -10px;

      width: 6px;
      height: 6px;
      border-radius: 50%;

      content: '';
      background-color: ${({ theme }) => theme.colors.black};
    }
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    li {
      margin-top: ${pxToRem(6)};
    }
  }
`

export const Image = styled.img`
  width: ${pxToRem(30)};

  :last-of-type {
    width: ${pxToRem(45)};
  }
`

export const WrapCard = styled.div<Iprops>`
  position: relative;
  z-index: ${({ debit }) => (debit ? 4 : 3)};
`
