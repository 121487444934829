import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import Provider from 'Provider'

import GlobalStyle from 'styles/global'

import Routes from 'routes'

import Header from 'components/Template/Header'

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <GlobalStyle />
      <Header />
      <Routes />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
