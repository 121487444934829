import styled from 'styled-components'

import { Container as Box } from 'components/Box/styles'
import { pxToRem } from 'utils'

export const Container = styled.div`
  max-width: ${pxToRem(860)};
  margin: 0 auto;
  margin-top: -134px;
  z-index: 999;
  position: relative;

  ${Box} {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    padding: ${pxToRem(50)};
    border-radius: ${pxToRem(10)};

    box-shadow: 0 0 6px ${({ theme }) => theme.colors.black2};

    @media ${({ theme }) => theme.mediaQueries.sm} {
      padding: ${pxToRem(20)} ${pxToRem(14)};
    }

    svg {
      width: 100%;
      height: 80px;
    }
  }
`

export const Message = styled.div`
  text-align: center;

  h1 {
    color: ${({ theme }) => theme.colors.fontPrimary};
    font-size: ${pxToRem(21)};
    font-family: ${({ theme }) => theme.fontTypes.bold};
  }

  p {
    font-family: ${({ theme }) => theme.fontTypes.book};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    p {
      margin-top: ${pxToRem(12)};
    }
  }
`
