import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'
import { rgba, lighten } from 'polished'

import { themeColors } from 'styles/theme'
import { pxToRem } from 'utils'

export interface IProp extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: any
  children?: any
  loading?: boolean
  disabled?: boolean
  color: keyof typeof themeColors
  onClick?: () => void
}

export const Container = styled.button<IProp>`
  height: ${pxToRem(60)};

  border: none;

  position: relative;

  display: inline-block;

  background-color: ${({ theme, color }) => theme.colors[color]};

  overflow: hidden;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  box-shadow: ${({ theme }) => `0 4px 4px ${rgba(theme.colors.black, 0.25)}`};

  border-radius: ${pxToRem(29)};
  height: ${pxToRem(47)};
  width: ${pxToRem(322)};

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const Handler = styled.div<IProp>`
  width: 100%;
  height: 100%;

  border: none;

  cursor: pointer;
  background: none;

  color: ${({ theme }) => theme.colors.white};
  font-size: ${pxToRem(15)};
  font-weight: bold;

  transition: 0.3s;

  z-index: 1;

  :after {
    content: '';
    width: ${pxToRem(116)};
    height: ${pxToRem(93)};
    border-radius: 100%;

    position: absolute;
    top: 16%;
    left: 0;
    transform: translate(-20%, -50%);

    background-color: ${({ theme, color }) =>
      lighten(0.04, theme.colors[color])};

    transition: 0.3s;
    z-index: 1;
  }

  :hover {
    :after {
      transform: translate(-10%, -50%);
      transition: 0.3s;
      box-shadow: ${({ theme }) =>
        `2px 0px 25px 1px ${rgba(theme.colors.black, 0.25)}`};
      z-index: 1;
    }
  }
`

export const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 30;
  position: relative;
  top: 9px;

  svg {
    margin-left: 45px;
  }
`

export const Loader = styled.img`
  width: ${pxToRem(45)};
`

export const Text = styled.span`
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${pxToRem(85)};
`
