import 'styled-components'
import { DefaultTheme } from 'styled-components'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: { [key in keyof typeof themeColors]: string }
    fontTypes: { [key in keyof typeof fontTypes]: any }
    mediaQueries: { [key in keyof typeof mediaQueries]: string }
  }
}

export const gridConfig = {
  breakpoints: {
    xl: 1280,
    lg: 1280,
    md: 980,
    sm: 768,
    xs: 480
  },
  row: {
    padding: 7
  },
  col: {
    padding: 7
  },
  container: {
    padding: 7,
    maxWidth: {
      xl: 1280,
      lg: 1280,
      md: 980,
      sm: 768,
      xs: 480
    }
  }
}

export const mediaQueries = {
  xs: `(max-width: ${gridConfig.breakpoints.xs}px)`,
  sm: `(max-width: ${gridConfig.breakpoints.sm}px)`,
  md: `(max-width: ${gridConfig.breakpoints.md}px)`,
  lg: `(max-width: ${gridConfig.breakpoints.lg}px)`,
  xl: `(max-width: ${gridConfig.breakpoints.xl}px)`,
  smUp: `(min-width: ${gridConfig.breakpoints.sm}px)`,
  mdUp: `(min-width: ${gridConfig.breakpoints.md}px)`,
  lgUp: `(min-width: ${gridConfig.breakpoints.lg}px)`,
  xlUp: `(min-width: ${gridConfig.breakpoints.xl}px)`
}

export const themeColors = {
  primary: '#00A0E6',
  primaryLight: '#4EA3F8',
  primaryDark: '#065E8D',
  secondary: '#004556',
  success: '#74E601',
  warning: '#FFC700',
  danger: '#F22563',
  red: '#ffd1d1',
  red2: '#f00',
  fontPrimary: '#2F5768',
  fontSecondary: '#7D8898',
  gray: '#E8E8E8',
  gray2: '#F1F1F1',
  gray3: '#a0a8ac',
  darkGray: '#707070',
  white: '#FFF',
  marmore: '#f9f9f9',
  marmore2: '#f4f4f4',
  black: '#000',
  black2: '#00000029',
  blue: '#0107D7',
  green: '#00b987',
  green2: '#efefef',
  cian: '#77b7a7',
  yellow: '#fcc984'
}

export const fontTypes = {
  book: 'CircularStd-Book',
  medium: 'CircularStd-Medium',
  bold: 'CircularStd-Bold',
  black: 'CircularStd-Black'
}

const theme: DefaultTheme = {
  colors: themeColors,
  mediaQueries,
  fontTypes
}

export default theme
