import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, InputAlign, Legend, BoxTest, CardsGrid } from './styles'
import { useForm, FormProvider } from 'react-hook-form'

import { getCards } from 'store/reducers/weblink'
import { Input } from 'components'
import { yupResolver } from '@hookform/resolvers'
import { schema, IFormInputs } from './schema'
import { cards } from './mock'

interface FormularyProps {
  methods: any
  showDropDown: boolean
  disponiveis: any
  setShowDropDown: any
}

const FormPayment: React.FC<FormularyProps> = ({
  methods,
  showDropDown,
  disponiveis,
  setShowDropDown
}) => (
  <FormProvider {...methods}>
    <Input
      label=""
      mask=""
      name="number"
      onClick={() => setShowDropDown(!showDropDown)}
    />

    {showDropDown && (
      <BoxTest>
        <Input
          label=""
          name=""
          mask=""
          onClick={() => setShowDropDown(!showDropDown)}
        />
        <CardsGrid>
          <Legend>MEUS CARTÕES</Legend>
          {disponiveis.map((card: any) => {
            return console.log(card)
          })}
        </CardsGrid>
      </BoxTest>
    )}
  </FormProvider>
)

const PaymentDetail: React.FC = () => {
  const dispatch = useDispatch()
  const [disponiveis, setDisponiveis] = useState<any>([])
  const [indisponiveis, setIndisponiveis] = useState<any>([])

  useEffect(() => {
    dispatch(getCards())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  })

  const [showDropDown, setShowDropDown] = useState(false)

  useEffect(() => {
    cards.map((card) => {
      return card.type === 'disponivel'
        ? setDisponiveis({ ...disponiveis, card })
        : setIndisponiveis({ ...indisponiveis, card })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, disponiveis, indisponiveis])

  return (
    <Container>
      <InputAlign>
        <Legend>Selecione sua forma de pagamento</Legend>

        <FormPayment
          methods={methods}
          showDropDown={showDropDown}
          disponiveis={disponiveis}
          setShowDropDown={setShowDropDown}
        />

        {!showDropDown && <Legend add>Adicionar forma de pagamento</Legend>}
      </InputAlign>
    </Container>
  )
}

export default PaymentDetail
