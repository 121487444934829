import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Box, Loading } from 'components'

import { IStore } from 'store'
// import { isPayed } from "store/reducers/weblink";

import { Container, Title } from './styles'

const FeedbackDebit: React.FC = () => {
  // const dispatch = useDispatch();
  const {
    loading,
    // data,
    isPayed: isPaid
  } = useSelector((state: IStore) => state?.weblink)

  useEffect(() => {
    // if(!isPaid) getDataWeblink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaid])

  // async function getDataWeblink() {
  //   dispatch(isPayed(data.hash));
  // }

  return (
    <Container>
      <Box>
        <Title>Estamos verificando se o pagamento foi concluído :)</Title>
        Caso não consiga acessar a página de pagamento, tente com o link abaixo:
        -link-
        {loading && <Loading />}
      </Box>
    </Container>
  )
}

export default FeedbackDebit
