import * as yup from 'yup'

export interface IFormInputs {
  number: string
  name: string
  date: string
  cvc: string
  doc: string
}

export const schema = yup.object().shape({
  number: yup
    .string()
    .test('test-number', 'Número de cartão inválido', () =>
      // value
      {
        // valid.number(value).isValid
        return true
      }
    )
    .required('Campo obrigatório')
})
