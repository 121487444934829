import React, { useCallback } from 'react'

import { Container, Content, AlignContent } from './styles'

import { IModalProps } from './interfaces'

const Modal: React.FC<IModalProps> = ({
  id = 'outside',
  onClose,
  children,
  type
}) => {
  const handleOutsideClick = useCallback(
    (e: any) => {
      if (e.target.id === id) onClose()
    },
    [id, onClose]
  )

  return (
    <Container id={id} onClick={(e) => handleOutsideClick(e)}>
      <Content type={type}>
        <AlignContent>{children}</AlignContent>
      </Content>
    </Container>
  )
}

export default Modal
