import { SelectHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import Icon from 'components/IconExporter'
import { pxToRem } from 'utils'

const inputDefaultStyle = css`
  height: ${pxToRem(56)};
  width: ${({ width }: any) => `${width}%`};
  align-items: center;
  display: flex;
  padding: 0 ${pxToRem(7)};

  #prefix {
    padding-left: ${pxToRem(7)};
  }
  #suffix {
    padding-right: ${pxToRem(7)};
  }
  :focus-within {
  }
`

const inputErrorStyle = css`
  /* border-bottom: ${pxToRem(2)} solid ${(props) =>
    props.theme.colors.warning}; */
  color: ${(props) => props.theme.colors.warning};
  /* :focus-within {
    border-bottom: ${pxToRem(2)} solid ${(props) => props.theme.colors.warning};
  } */
`

export const InputGroupWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: ${pxToRem(29)};
`

export const InputWrapper = styled.div<Input>`
  ${inputDefaultStyle}
  ${({ error }: any) => error && inputErrorStyle}
  position: relative;

  > div {
    width: 100%;

    > span {
      background: none !important;
      border: none !important;
    }

    > div {
      :nth-of-type(1) {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
`

export const InputLabel = styled.div<Input>`
  color: ${(props) => props.theme.colors.primary};
  padding-left: ${pxToRem(14)};
  padding-top: ${pxToRem(7)};
`

export const InputAddon = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  font: inherit;
  height: 100%;
`

export const ErrorMessage = styled.span`
  align-items: center;
  bottom: 0;
  color: ${(props) => props.theme.colors.warning};
  display: flex;
  left: ${pxToRem(14)};
  margin-bottom: ${pxToRem(-28)};
  position: absolute;
  width: 100%;
`

export const ErrorIcon = styled(Icon)`
  fill: ${(props) => props.theme.colors.warning};
  margin-right: ${pxToRem(7)};
`

export const Input = styled.select`
  background-color: inherit;
  border: none;
  color: inherit;
  font: inherit;
  padding: 0 ${pxToRem(7)};
  width: inherit;

  &:-webkit-autofill {
    box-shadow: none;
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.primary}
      inset;
  }

  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 300;
  }
`

export const InputFieldGroup = styled.div<Input>`
  position: relative;

  ${({ errorStatic }) =>
    errorStatic &&
    css`
      ${ErrorMessage} {
        position: static;
        margin-bottom: 0;
        margin-top: ${pxToRem(5)};
      }
    `};
`

export interface Input {
  errorStatic?: any
  htmlFor?: any
  width?: string
  error?: any
}

export interface IProp extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  options?: any
  error?: any
  placeholder?: string
  onChange?: (e: any) => any
  suffix?: string
  label?: string
  width?: string
  errorStatic?: any
  noOptionsMessage?: string
}
