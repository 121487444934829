import styled, { css } from 'styled-components'

import { Container as Box } from 'components/Box/styles'
import { Container as Input } from 'components/Input/styles'
import { pxToRem } from 'utils'

export const Container = styled.div`
  display: flex;
  justify-content: center;

  ${Box} {
    padding: ${pxToRem(30)};
    padding-top: ${pxToRem(50)};
  }

  form {
    padding: 0;
    width: 100%;
  }
`

export const AlignGrids = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: column-reverse;
  }
`

export const FormGrid = styled.div`
  /* max-width: ${pxToRem(310)}; */
  width: 100%;

  ${Input} {
    width: ${pxToRem(310)};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    ${Input} {
      width: 100%;
    }
  }
`

export const CardGrid = styled.div`
  .rccs {
    display: flex;
    justify-content: flex-end;

    transform: scale(1);

    .rccs__card {
      margin: 0;

      width: 265px;
      height: 167px;

      .rccs__number {
        font-size: 19px;
      }

      .rccs__name {
        font-size: 14px;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.md} {
    .rccs {
      justify-content: center;

      margin-bottom: ${pxToRem(30)};

      width: 100%;
      transform: scale(1);

      .rccs__card {
        height: ${pxToRem(216)};
        width: 100%;
        max-width: ${pxToRem(345)};

        .rccs__number {
          font-size: 24px;
        }

        .rccs__name {
          font-size: 18px;
        }
      }
    }
  }
`

export const Line = styled.div<any>`
  ${({ dual }: any) =>
    dual &&
    css`
      display: flex;
      justify-content: space-between;

      div {
        max-width: ${pxToRem(146)};

        + div {
          display: flex;
          justify-content: flex-end;
        }
      }

      @media ${({ theme }) => theme.mediaQueries.md} {
        max-width: 100%;

        div {
          max-width: 49%;
        }
      }
    `}

  @media ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;

    ${({ dual }: any) =>
      dual &&
      css`
        width: 100%;
      `}
  }

  & + & {
    margin-top: ${pxToRem(15)};
  }
`

export const Warning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${pxToRem(39)};
  margin: ${pxToRem(17)} ${pxToRem(-20)} ${pxToRem(16)};
  width: calc(100% + ${pxToRem(40)});

  background-color: ${({ theme }) => theme.colors.yellow};

  svg {
    width: ${pxToRem(17)};
    height: ${pxToRem(15)};
    margin-right: ${pxToRem(6)};
  }

  span {
    text-align: center;
    font-size: ${pxToRem(14)};
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;

    height: ${pxToRem(66)};

    svg {
      margin-bottom: ${pxToRem(12)};
    }
  }
`
