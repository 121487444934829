import styled, { css } from 'styled-components'
import InputMask from 'react-input-mask'

import { pxToRem } from 'utils'
import { IFieldProp } from './interfaces'

export const Container = styled.div<any>`
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.danger : theme.colors.darkGray};

  position: relative;
  width: 100%;
  border-radius: ${pxToRem(29)};
  padding: ${pxToRem(14)} ${pxToRem(24)};

  display: flex;
  flex-direction: column;

  cursor: text;
  background: ${({ theme }) => theme.colors.white};

  :focus-within {
    transition: 0.3s;
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.colors.danger : theme.colors.primary};
  }
`

export const Label = styled.label<any>`
  color: ${({ theme }) => theme.colors.fontSecondary};
  font-size: ${pxToRem(13)};
  margin-bottom: ${pxToRem(10)};
  position: absolute;

  top: 50%;
  left: ${pxToRem(20)};
  transform: translateY(-50%);
  transition: 0.3s;

  cursor: text;

  ${({ focus }: any) =>
    focus &&
    css`
      top: 28%;
      left: ${pxToRem(24)};
      font-size: ${pxToRem(10)};
    `}

  ${({ error, theme }: any) =>
    error &&
    css`
      color: ${theme.colors.danger};
      text-transform: uppercase;
    `}
`

export const Field = styled(InputMask)<IFieldProp>`
  border: none;
  transition: 0.3s;
  font-size: ${pxToRem(14)};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.fontPrimary};
  font-family: ${({ theme }) => theme.fontTypes.book};

  transform: translateY(35%);
`
