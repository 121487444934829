import React from 'react'

import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

// PAGINA HOME
import {
  Home,
  NotFound,
  FeedbackDebit,
  PaymentDetail,
  PaymentFeedback
} from 'pages'

import { history } from 'store'

const Routes: React.FC = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/select/card">
          <PaymentDetail />
        </Route>
        <Route path="/debito/feedback" exact>
          <FeedbackDebit />
        </Route>
        <Route path="/:hash" exact>
          <Home />
        </Route>
        <Route path="/pagamento/feedback" exact>
          <PaymentFeedback />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </ConnectedRouter>
  )
}

export default Routes
