import { createGlobalStyle } from 'styled-components'

import { pxToRem } from 'utils'

import 'react-credit-cards/es/styles-compiled.css'
import 'react-notifications-component/dist/theme.css'

export default createGlobalStyle`
    body, #root {
        font-family: ${({ theme }) => theme.fontTypes.medium};
        font-size: ${pxToRem(16)};

        width: 100%;
        min-height: 100vh;

        -webkit-font-smoothing: antialiased;
    }

    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        outline: none;
    }

    *::before,
    *::after {
        box-sizing: inherit;
    }    

    .rccs {
        transform: scale(0.8);

        @media ${({ theme }) => theme.mediaQueries.md} {
            transform: scale(1.2);
        }
    }
`
