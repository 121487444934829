import React, { useState, useEffect } from 'react'
import { uuid } from 'uuidv4'

import { IFieldProp } from './interfaces'

import { Container, Label, Field } from './styles'

const Input = React.forwardRef<HTMLInputElement, IFieldProp>((props, ref) => {
  const [focused, setFocused] = useState(false)

  const id = uuid()

  useEffect(() => {
    if (focused) {
      document.getElementById(id)?.focus()
    } else {
      document.getElementById(id)?.blur()
    }
  }, [focused, id])

  return (
    <Container onClick={() => setFocused(true)} error={!!props?.error?.message}>
      <Label focus={focused || props.value} error={props?.error?.message}>
        {props?.error ? props?.error.message : props.label}
      </Label>

      <Field
        focus={focused || !!props.value}
        id={id}
        error={!!props?.error?.message}
        onFocus={() => {
          props.handleFocus && props.handleFocus(props.name)
          setFocused(true)
        }}
        onBlur={() => setFocused(false)}
        inputRef={ref}
        maskChar=""
        {...props}
      />
    </Container>
  )
})

export default Input
