import React from 'react'
import { Icon } from 'react-icons-kit'

import loadingSource from 'assets/img/loading.svg'

import { themeColors } from 'styles/theme'

import { IProp, Container, Handler, IconWrap, Loader, Text } from './styles'

const Button = ({
  icon,
  loading,
  disabled,
  color,
  children,
  ...rest
}: IProp) => (
  <Container color={color} loading={loading} disabled={disabled} {...rest}>
    <Handler color={color} loading={loading}>
      {loading ? (
        <Loader src={loadingSource} />
      ) : (
        <IconWrap>
          {icon && (
            <Icon size={30} style={{ color: themeColors.white }} icon={icon} />
          )}
          <Text>{children}</Text>
        </IconWrap>
      )}
    </Handler>
  </Container>
)

export default Button
