import styled from 'styled-components'

import { pxToRem } from 'utils'

export const Container = styled.div``

export const Item = styled.div`
  :nth-of-type(1) {
    width: 60%;
  }

  :nth-of-type(2) {
    width: 20%;
  }

  :nth-of-type(3) {
    width: 20%;
  }
`

export const Header = styled.div`
  background: ${({ theme }) => theme.colors.marmore};
  border-radius: ${pxToRem(6)};
  padding: ${pxToRem(17)} ${pxToRem(25)};

  display: flex;

  justify-content: space-between;

  ${Item} {
    font-size: ${pxToRem(13)};
    color: ${({ theme }) => theme.colors.fontSecondary};
    font-weight: 600;
  }
`

export const Body = styled.div``

export const Row = styled.div`
  padding: ${pxToRem(20)} ${pxToRem(25)};
  display: flex;
  justify-content: space-between;

  ${Item} {
    font-size: ${pxToRem(15)};
    color: ${({ theme }) => theme.colors.fontPrimary};
    font-weight: 600;
  }
`

export const Footer = styled.div`
  border-top: ${pxToRem(1)} solid ${({ theme }) => theme.colors.marmore2};

  display: flex;
  justify-content: flex-end;

  padding: ${pxToRem(15)} ${pxToRem(25)};

  ${Item} {
    width: 20%;

    font-size: ${pxToRem(18)};
    color: ${({ theme }) => theme.colors.fontPrimary};
    font-weight: 600;

    display: flex;
    flex-direction: column;
  }
`

export const Small = styled.small`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: ${pxToRem(11)};
  font-weight: normal;
`
