import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { configureStore, combineReducers } from '@reduxjs/toolkit'

import thunk from 'redux-thunk'

import * as reducers from './reducers'

export const history = createBrowserHistory()

export interface IStore {
  weblink: {
    error: boolean
    loading: boolean
    loadingPost: boolean
    isPayed: boolean
    data: {
      status: string
      hash: string
    }
  }
}
const store = configureStore({
  reducer: combineReducers({
    router: connectRouter(history),
    ...reducers
  }),
  middleware: [thunk, routerMiddleware(history)]
})

export default store
