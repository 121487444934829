import React from 'react'

import { Container, Triangle, HideBorder } from './styles'

interface TooltipProps {
  children?: React.ReactNode
  className?: string
}

const Tooltip: React.FC<TooltipProps> = ({ children, className = '' }) => {
  return (
    <>
      <Triangle />
      <HideBorder />
      <Container className={className}>{children}</Container>
    </>
  )
}

export default Tooltip
