import styled, { css } from 'styled-components'
import { shade } from 'polished'

import { pxToRem } from 'utils'

export const ModalAlign = styled.div<any>`
  padding: ${pxToRem(40)};

  button {
    width: ${pxToRem(200)};
    height: ${pxToRem(47)};
    border: ${pxToRem(1)} solid ${({ theme }) => theme.colors.white};
    border-radius: ${pxToRem(29)};
    background-color: ${({ theme }) => theme.colors.red2};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
    padding: ${pxToRem(14)} ${pxToRem(14)};
    font-size: ${pxToRem(16)};
    margin-top: ${pxToRem(20)};
    cursor: pointer;
    font-size: ${pxToRem(12)};

    &.exit {
      width: ${pxToRem(100)};

      margin-top: 35px;
      margin-bottom: -40px;
      background-color: ${({ theme }) => `${shade(0.2, theme.colors.green)}`};
    }
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    height: 100%;
    padding: ${pxToRem(180)} ${pxToRem(27)};

    ${({ info }) =>
      info &&
      css`
        padding: 36px;
        position: relative;
      `}

    button {
      left: 25%;
      right: 25%;
      width: 50%;
      bottom: ${pxToRem(100)};
      position: absolute;

      &.exit {
        margin: auto;
        margin-bottom: 20px;
      }
    }
  }
`

export const ImageModal = styled.img<any>`
  margin-top: ${({ info }) => (info ? '100px' : pxToRem(8))};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    width: ${({ info }) => (info ? 'auto' : pxToRem(48))};
  }
`

export const TextModal = styled.p<any>`
  margin-top: ${pxToRem(22)};

  text-align: center;
  letter-spacing: 0;
  font-family: ${({ theme }) => theme.fontTypes.book};
  font-size: ${({ title }) => (title ? pxToRem(17) : pxToRem(13))};
  color: ${({ black, theme }) =>
    black ? theme.colors.black : theme.colors.white};

  &:last-child {
    margin-top: ${pxToRem(32)};
  }

  ${({ info }) =>
    info &&
    css`
      margin-top: 0px;
      font-size: 16.5px;
    `}
`

export const ContextModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: ${pxToRem(114)};
  padding: ${pxToRem(27)} ${pxToRem(39)} ${pxToRem(33)};

  margin: ${pxToRem(33)} ${pxToRem(-40)} ${pxToRem(-15)};

  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  ${ImageModal} {
    margin: 0;
  }

  ${TextModal} {
    margin: 0;
    margin-left: ${pxToRem(23)};

    font-size: ${pxToRem(14)};
    text-align: left;
    line-height: ${pxToRem(22)};
    letter-spacing: 0;

    > i {
      font-family: ${({ theme }) => theme.fontTypes.medium};
    }

    > span a {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;

    margin: ${pxToRem(33)} ${pxToRem(-10)} 0;
    padding: ${pxToRem(40)} ${pxToRem(25)} ${pxToRem(50)};

    border-radius: ${pxToRem(20)};

    ${TextModal} {
      display: flex;
      align-items: center;
      flex-direction: column;

      margin: 0;
      margin-top: ${pxToRem(18)};
      text-align: center;

      > i {
        font-size: ${pxToRem(18)};

        font-family: ${({ theme }) => theme.fontTypes.medium};
        font-style: normal;

        > u {
          text-decoration: none;
        }
      }

      > span {
        font-size: ${pxToRem(18)};
      }
    }
  }
`

export const Arrow = styled.div`
  border-bottom: ${pxToRem(20)} solid ${({ theme }) => theme.colors.white};
  border-left: ${pxToRem(20)} solid transparent;
  border-right: ${pxToRem(20)} solid transparent;
  float: left;
  position: absolute;
  top: ${pxToRem(-20)};
`

export const Link = styled.a`
  position: absolute;
  color: ${({ theme }) => theme.colors.white};
  bottom: 133px;
  right: 22px;
  text-decoration: none;
  font-size: 13px;

  @media screen and (min-height: 680px) {
    bottom: 145px;
  }
`

export const AlignImageText = styled.div`
  width: 100;
  height: auto;
  display: flex;
  margin-top: 100px;
`

export const GridImage = styled.div`
  display: flex;
  flex-direction: column;

  ${ImageModal} {
    margin: 0;
  }
`

export const Sublinhado = styled.p<any>`
  text-align: center;
  letter-spacing: 0;
  font-family: ${({ theme }) => theme.fontTypes.book};
  font-size: ${({ title }) => (title ? pxToRem(17) : pxToRem(13))};
  color: ${({ black, theme }) =>
    black ? theme.colors.black : theme.colors.white};

  text-decoration: underline;
  margin-left: 6px;
`

export const GridText = styled.div`
  padding-left: 16px;
  ${TextModal} {
    text-align: left;
    margin-top: 10px;
    display: flex;

    :last-of-type {
      margin-top: 95px;

      @media screen and (min-width: 600px) {
        margin-top: 130px;
      }
    }
  }
`
