import styled, { css } from 'styled-components'
import { Field as Input } from 'components/Input/styles'
import { pxToRem } from 'utils'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10vw;
`

export const Title = styled.div`
  font-size: ${pxToRem(18)};
  font-family: ${({ theme }) => theme.fontTypes.book};
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.primaryDark};
  opacity: 1;
  padding-bottom: ${pxToRem(16)};
`

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${pxToRem(6)};
`

export const TextLeft = styled.div`
  text-align: left;
  font-size: ${pxToRem(12)};
  font-family: ${({ theme }) => theme.fontTypes.medium};
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.darkGray};
  opacity: 1;
`

export const TextRight = styled.div`
  text-align: right;
  font-size: ${pxToRem(12)};
  font-family: ${({ theme }) => theme.fontTypes.medium};
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.primaryDark};
  opacity: 1;
`

export const Total = styled.div`
  margin-top: ${pxToRem(20)};
  margin-right: ${pxToRem(-14)};
  display: flex;
  flex-direction: column;
  ${TextLeft} {
    text-align: right;
  }
`

export const Legend = styled.div<any>`
  text-align: center;
  font-size: ${pxToRem(18)};
  font-family: ${({ theme }) => theme.fontTypes.book};
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.darkGray};
  opacity: 1;

  :first-of-type {
    margin-bottom: ${pxToRem(23)};
  }

  ${({ add }: any) =>
    add &&
    css`
      margin-top: ${pxToRem(45)};
      margin-bottom: ${pxToRem(21)};
    `}
`

export const InputAlign = styled.div`
  width: ${pxToRem(625)};
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${pxToRem(40)};

  ${Input} {
    width: ${pxToRem(596)};
    height: ${pxToRem(48)};
  }
`

export const BoxTest = styled.div`
  margin-top: ${pxToRem(-30)};
  padding: ${pxToRem(20)};
  width: ${pxToRem(635)};
  background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0 0 9px ${({ theme }) => theme.colors.black2};
  border-radius: ${pxToRem(35)};
`

export const CardsGrid = styled.div`
  width: 100%;
  max-width: ${pxToRem(540)};
`

export const RowCard = styled.div``
