import React from 'react'

import { Container, ILoading } from './styles'

const Box: React.FC<ILoading> = ({ children, loading, isShadow }) => (
  <Container loading={loading} isShadow={isShadow}>
    {children}
  </Container>
)

export default Box
