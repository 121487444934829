import React, { useState } from 'react'
// import { useSelector } from "react-redux";

// import { IStore } from "store";
// import { isPayed } from "store/reducers/weblink";

import { ReactComponent as CheckIcon } from 'assets/icons/check-green.svg'
import { ReactComponent as DenyIcon } from 'assets/icons/deny-red.svg'

import { Box, Loading } from 'components'

import { IStatusMessages } from './interfaces'

import { Container } from './styles'

const statusMessages: IStatusMessages = {
  processing: {
    title: 'Estamos verificando se o pagamento foi concluído.',
    description:
      'Caso não consiga acessar a página de pagamento, tente com o link abaixo: -link-',
    icon: <Loading />
  },

  success: {
    title: 'Pagamento efetuado com sucesso!',
    icon: <CheckIcon />
  },

  error: {
    title: 'Ocorreu um erro ao efetuar o pagamento!',
    description: 'Entre em contato com a sua operadora.',
    icon: <DenyIcon />
  }
}

const PaymentFeedback: React.FC = () => {
  const [status] = useState<'processing' | 'success' | 'error'>('success')

  // const dispatch = useDispatch();
  // const {
  //   loading,
  //   data,
  //   isPayed: isPaid,
  // } = useSelector((state: IStore) => state?.weblink);

  // useEffect(() => {
  //   if(!isPaid) getDataWeblink()
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isPaid]);

  // async function getDataWeblink() {
  //   dispatch(isPayed(data.hash));
  // }

  return (
    <Container>
      <Box>
        {/* <Message>
          <h1>{statusMessages[status].title}</h1>
          {statusMessages[status].description && (
            <p>{statusMessages[status].description}</p>
          )}
        </Message> */}

        {/* loading && <Loading /> */}
        {statusMessages[status].icon}
      </Box>
    </Container>
  )
}

export default PaymentFeedback
