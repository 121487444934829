import React from 'react'

import { Provider as ReduxProvider } from 'react-redux'

import { ThemeProvider } from 'styled-components'
import { ScreenClassProvider } from 'react-grid-system'
import ReactNotification from 'react-notifications-component'

import store from 'store'
import theme from 'styles/theme'

const Provider: React.FC = ({ children }) => {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <ReactNotification />
        <ScreenClassProvider>{children}</ScreenClassProvider>
      </ThemeProvider>
    </ReduxProvider>
  )
}

export default Provider
