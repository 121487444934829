import styled, { css } from 'styled-components'

import { Container as FormComponent } from 'components/Form/styles'
import { pxToRem } from 'utils'

export const Legend = styled.div<any>`
  text-align: center;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontTypes.book};
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.darkGray};
  opacity: 1;

  ${({ bottom }: any) =>
    bottom &&
    css`
      margin-top: ${pxToRem(27)};
    `}

  ${({ top }: any) =>
    top &&
    css`
      margin-top: ${pxToRem(45)};
    `}
`

export const FilterCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 ${pxToRem(27)};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0;
  }
`

export const MyCards = styled.div`
  display: flex;
  align-items: center;
  height: ${pxToRem(49)};
  text-align: left;
  width: 100%;
  cursor: pointer;
  & + & {
    border-top: ${pxToRem(1)} solid ${({ theme }) => theme.colors.gray};

    @media ${({ theme }) => theme.mediaQueries.sm} {
      border-bottom: ${pxToRem(1)} solid ${({ theme }) => theme.colors.gray};
    }
  }

  position: relative;

  :last-of-type {
    margin-bottom: ${pxToRem(27)};
  }

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const Unavailable = styled.div`
  display: flex;
  align-items: center;
  height: ${pxToRem(49)};
  text-align: left;
  width: 100%;
  cursor: pointer;
  position: relative;
  & + & {
    border-top: ${pxToRem(1)} solid ${({ theme }) => theme.colors.gray};
  }

  > img,
  > span {
    opacity: 0.5;
  }

  :after {
    content: 'EXPIRADO';
    text-align: right;
    font-size: ${pxToRem(14)};
    font-family: ${({ theme }) => theme.fontTypes.medium};
    letter-spacing: 0;
    color: ${({ theme }) => theme.colors.red2};
    text-transform: uppercase;
    opacity: 1;
    position: absolute;
    right: 0;
  }
`

export const CardImage = styled.img<any>`
  width: ${pxToRem(35)};
  margin-right: ${pxToRem(6)};
`

export const Form = styled(FormComponent)`
  && {
    display: flex;
    flex-direction: column;

    width: 100%;

    border: 0;
    padding: 0;

    button {
      align-self: center;
      margin-top: ${pxToRem(56)};
      margin-bottom: ${pxToRem(10)};
    }
  }
`
