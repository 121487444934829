import React from 'react'

import { AlignChange, Legend } from './styles'

interface PaymentInterface {
  typeSelected: any
  hideLink: boolean
  setTypeSelected: any
  setDebitDropdownIsOpened: any
  setCreditDropdownIsOpened: any
  setPixDropdownIsOpened: any
  setSelectedCard: any
}

const LegendPayment: React.FC<PaymentInterface> = ({
  typeSelected,
  hideLink,
  setTypeSelected,
  setDebitDropdownIsOpened,
  setCreditDropdownIsOpened,
  setPixDropdownIsOpened,
  setSelectedCard
}: PaymentInterface) => (
  <>
    {(typeSelected === 1 || typeSelected === 2 || typeSelected === 3) && (
      <AlignChange somethingOpen={typeSelected !== ''} shoulShrink={hideLink}>
        <Legend>Selecione sua forma de pagamento</Legend>
        {!hideLink && typeSelected !== '' && (
          <a
            // eslint-disable-next-line no-script-url
            href="javascript:;"
            onClick={() => {
              setTypeSelected('')
              setDebitDropdownIsOpened(false)
              setCreditDropdownIsOpened(false)
              setPixDropdownIsOpened(false)
              setSelectedCard(null)
            }}
          >
            Alterar
          </a>
        )}
      </AlignChange>
    )}
  </>
)

export default LegendPayment
