export default () => {
  const screenHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  const timeZoneOffset = Math.floor(new Date().getTimezoneOffset() / 60)
  // eslint-disable-next-line no-restricted-globals
  const colorDepth = screen && screen.colorDepth

  return {
    colorDepth,
    timeZoneOffset,
    screenWidth,
    screenHeight
  }
}
