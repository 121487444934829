export const cards = [
  {
    id: '1',
    type: 'disponivel',
    number: '1234432112344321',
    name: 'teste',
    cpf: '12312312312',
    expirationData: '22-12-2021',
    securitCod: '992'
  },
  {
    id: '2',
    type: 'disponivel',
    number: '1234432112344321',
    name: 'teste',
    cpf: '12312312312',
    expirationData: '22-12-2021',
    securitCod: '992'
  },
  {
    id: '3',
    type: 'indisponível',
    number: '1234432112344321',
    name: 'teste',
    cpf: '12312312312',
    expirationData: '22-12-2021',
    securitCod: '992'
  }
]
