import styled from 'styled-components'

import { Container as Box } from 'components/Box/styles'
import { pxToRem } from 'utils'

export const Container = styled.div`
  max-width: ${pxToRem(860)};

  margin: 0 auto;

  ${Box} {
    padding: ${pxToRem(50)};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.fontPrimary};
  font-size: ${pxToRem(21)};
`
