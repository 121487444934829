import React, { useState, useRef, useEffect } from 'react'

import { themeColors } from 'styles/theme'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Icon } from 'react-icons-kit'
import { qrcode } from 'react-icons-kit/fa/qrcode'
import { ic_link } from 'react-icons-kit/md/ic_link'
import { ic_content_copy } from 'react-icons-kit/md/ic_content_copy'
import { whatsapp } from 'react-icons-kit/fa/whatsapp'

import {
  Container,
  Item,
  ContainerOption,
  QrCode,
  LinkContainer,
  GridButtons,
  Tooltip,
  Label
} from './styles'

import { Button } from 'components'

interface PixForm {
  setTypeSelected: any
  typeSelected: any
  qrCode?: string
  link?: string
  pix?: any
}

const PixForm: React.FC<PixForm> = ({ setTypeSelected, qrCode, pix, link }) => {
  const [optionSelect, setOptionSelect] = useState('')
  const ref = useRef()

  const [state, setState] = useState({
    value: link,
    copied: false
  })

  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    if (link) {
      setState({
        value: link,
        ...state
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, pix])

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => setShowTooltip(false), 2000)
    }
  }, [showTooltip])

  return (
    <Container>
      <Item
        onClick={() => {
          setOptionSelect('qrcode')
          setTypeSelected(3)
          if (optionSelect !== '' && optionSelect === 'qrcode') {
            setOptionSelect('')
          }
        }}
      >
        <Icon size={30} style={{ color: themeColors.cian }} icon={qrcode} />
        <label>QR CODE</label>
      </Item>
      {optionSelect === 'qrcode' && (
        <ContainerOption>
          <label>
            Leia o QR Code através do app do banco desejado para fazer a
            transação
          </label>
          <QrCode src={qrCode} />
        </ContainerOption>
      )}
      <Item
        borderTop
        onClick={() => {
          setOptionSelect('link')
          setTypeSelected(3)
          if (optionSelect !== '' && optionSelect === 'link') {
            setOptionSelect('')
          }
        }}
      >
        <Icon size={30} style={{ color: themeColors.cian }} icon={ic_link} />
        <label>LINK</label>
      </Item>
      {optionSelect === 'link' && (
        <>
          <ContainerOption>
            <label>Copie este link e cole no app do seu banco</label>
            <Tooltip showTooltip={showTooltip}>Copiado!</Tooltip>
            <CopyToClipboard
              text={state.value}
              onCopy={() => {
                setState({ ...state, copied: true })
                setShowTooltip(true)
              }}
            >
              <LinkContainer>
                <textarea
                  disabled
                  onChange={({ target: { value } }) =>
                    setState({ value, copied: false })
                  }
                  rows={link?.length > 90 ? 4 : 2}
                  value={link}
                  ref={ref}
                />
              </LinkContainer>
            </CopyToClipboard>
            <GridButtons>
              <CopyToClipboard
                text={state.value}
                onCopy={() => {
                  setState({ ...state, copied: true })
                  setShowTooltip(true)
                }}
              >
                <Button
                  icon={ic_content_copy}
                  color="primaryLight"
                  type="submit"
                >
                  COPIAR LINK
                </Button>
              </CopyToClipboard>
              <a
                href={`https://wa.me?text=${encodeURI(link)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button icon={whatsapp} color="primaryLight" type="submit">
                  COMPARTILHAR LINK
                </Button>
              </a>
            </GridButtons>
          </ContainerOption>
          <Label>
            Este link irá expirar em {pix?.expire_date} as {pix?.expire_time}
          </Label>
        </>
      )}
    </Container>
  )
}

export default PixForm
