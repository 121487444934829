import styled from 'styled-components'

export const Container = styled.div`
  left: 50%;
  transform: translate(-50%);
  top: 66px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: initial;

  width: 100%;
  max-width: 352px;
  height: 252.53px;

  border-radius: 9px;
  box-shadow: 0 0 3px ${({ theme }) => theme.colors.black};

  z-index: 2;

  background: ${({ theme }) => theme.colors.white};
`

export const HideBorder = styled.div`
  position: absolute;
  bottom: -46%;
  left: 50%;
  background: rgb(255, 255, 255);
  transform: translate(-50%);
  z-index: 11;
  width: 60px;
  height: 8px;
  border-radius: 5003px 5107px 0px 0px;
`

export const Triangle = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 1px ${({ theme }) => theme.colors.black};

  width: 44px;
  height: 44px;
  border-radius: 5px;
  z-index: 1;

  position: absolute;
  bottom: -86%;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
`
