import React from 'react'

import { Container, Wrap } from './styles'

interface IProp {
  onSubmit?: (e: any) => any
}

const Form: React.FC<IProp> = ({ onSubmit, children }) => (
  <Container onSubmit={onSubmit}>
    {/* <Title>método de pagamento</Title> */}

    <Wrap>{children}</Wrap>
  </Container>
)

export default Form
