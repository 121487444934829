import styled, { css } from 'styled-components'

import { pxToRem } from 'utils'

import { Container as Box } from 'components/Box/styles'

export const Container = styled.div`
  max-width: ${pxToRem(860)};

  margin: 64px auto 0;

  ${Box} {
    padding: ${pxToRem(50)};

    display: flex;
    justify-content: center;

    background-color: transparent;
  }
`

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.fontPrimary};
    font-family: ${theme.fontTypes.bold};
  `}
  font-size: ${pxToRem(21)};
`
