import axios from 'axios'

import { createSlice } from '@reduxjs/toolkit'
import { ThunkDispatch } from 'redux-thunk'
// import { push } from "connected-react-router";

import { AnyAction } from 'redux'
// import { store } from "react-notifications-component";

import { removeCharacters, getClientInfo } from 'utils'
import { postPaymentWeblink, getWeblink, isWeblinkPayed } from 'services/api'
// import { error } from 'console';
// import { actions as notificationAction } from "store/reducers/notifications";

const weblink = createSlice({
  name: 'weblink',
  initialState: {
    captcha: false,
    openChallengeModal: false,
    openSuccessModal: false,
    openErrorModal: false,
    loading: true,
    loadingPost: false,
    successPost: false,
    error: null,
    success: null,
    counter: 0,
    data: {},
    textHeader: 'Estamos buscando as informações de sua solicitação.',
    notification: 'error'
  },
  reducers: {
    setRecaptcha: (state, action) => ({
      ...state,
      captcha: action.payload
    }),
    toggleChallengeModal: (state, action) => ({
      ...state,
      openChallengeModal: action.payload,
      openSuccessModal: false,
      openErrorModal: false
    }),
    toggleSuccessModal: (state, action) => ({
      ...state,
      openSuccessModal: action.payload,
      openChallengeModal: false,
      openErrorModal: false
    }),
    toggleFeedback: (state, action) => ({
      ...state,
      messageFeedback: action.payload
    }),
    toggleErrorModal: (state, action) => ({
      ...state,
      openErrorModal: action.payload,
      openChallengeModal: false,
      openSuccessModal: false
    }),
    closeAllModal: (state) => ({
      ...state,
      loading: false,
      loadingPost: false,
      openErrorModal: false,
      openChallengeModal: false,
      openSuccessModal: false
    }),
    dataWeblinkFetch: (state) => ({
      ...state,
      loading: true
    }),
    dataWeblinkSuccess: (state, action) => ({
      ...state,
      loading: false,
      textHeader: action.payload.textHeader,
      data: action.payload.data
    }),
    dataWeblinkIsPayed: (state, action) => ({
      ...state,
      loading: false,
      counter: action.payload.counter,
      isPayed: action.payload.isPayed
    }),
    dataWeblinkError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error,
      textHeader: action.payload.textHeader,
      data: {
        config: action.payload.config
      }
    }),
    dataSendPayment: (state) => ({
      ...state,
      loadingPost: true
    }),
    dataPayment: (state, action) => ({
      ...state,
      card: {
        ...action.payload
      }
    }),
    dataSendPaymentSuccess: (state, action) => ({
      ...state,
      loadingPost: false,
      successPost: true,
      loading: true,
      messageSuccess: action.payload.message
    }),
    dataSendPaymentError: (state) => ({
      ...state,
      loadingPost: false,
      notification: 'error'
    }),
    getText: (state, action) => ({
      ...state,
      textHeader: action.payload.message
    })
  }
})

// interface IDataWeblink {
//   uid: string
// }
export function dataWeblink(uid: string) {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(weblink.actions.dataWeblinkFetch())
      const { data } = await getWeblink(uid)

      const textHeader = `Olá ${data.detail.client}! Por favor, escolha a sua forma de pagamento`

      // f2b3abd6-50b7-47e7-945e-8d2b76bc3bf4

      /**
       * TEMP
       */
      // const data = {
      //   cards: [
      //     {
      //       id: '022463',
      //       available: true,
      //       number: '476608******8306',
      //       name: 'ELIZABETE DA SILVA',
      //       cpf: '21335546880',
      //       service: 'visa',
      //       expirationMonth: '04',
      //       expirationYear: '2022',
      //       token: '9M7ZcOOdsQNgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     },
      //     {
      //       id: '022469',
      //       available: true,
      //       number: '522688******4837',
      //       name: 'LEANDRO M SILVA',
      //       cpf: '21335546880',
      //       service: 'mastercard',
      //       expirationMonth: '03',
      //       expirationYear: '2028',
      //       token: '6oKOU39XHcpgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     },
      //     {
      //       id: '022471',
      //       available: true,
      //       number: '522688******6659',
      //       name: 'LEANDRO M SILVA',
      //       cpf: '21335546880',
      //       service: 'mastercard',
      //       expirationMonth: '09',
      //       expirationYear: '2028',
      //       token: 'zdSYkpY0rqpgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     },
      //     {
      //       id: '022605',
      //       available: true,
      //       number: '473200******3100',
      //       name: 'JULIANA SILVA SOUZA',
      //       cpf: '21335546880',
      //       service: 'visa',
      //       expirationMonth: '09',
      //       expirationYear: '2021',
      //       token: 'OCHMuZYRYXZgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     },
      //     {
      //       id: '022676',
      //       available: true,
      //       number: '485464******6510',
      //       name: 'RICARDO M SILVA',
      //       cpf: '21335546880',
      //       service: 'visa',
      //       expirationMonth: '07',
      //       expirationYear: '2024',
      //       token: 'knrUr/QdtMdgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     },
      //     {
      //       id: '023074',
      //       available: true,
      //       number: '516292******8948',
      //       name: 'RONDINELE L L SILVA',
      //       cpf: '21335546880',
      //       service: 'mastercard',
      //       expirationMonth: '04',
      //       expirationYear: '2028',
      //       token: '97gXNLY5Z2lgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     },
      //     {
      //       id: '023086',
      //       available: true,
      //       number: '532930******6121',
      //       name: 'FABIANA MARQUES',
      //       cpf: '21335546880',
      //       service: 'mastercard',
      //       expirationMonth: '07',
      //       expirationYear: '2024',
      //       token: '1o3mAp9mPnRgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     },
      //     {
      //       id: '023562',
      //       available: true,
      //       number: '525664******6688',
      //       name: 'DANIEL V A SIQUEIRA',
      //       cpf: '21335546880',
      //       service: 'mastercard',
      //       expirationMonth: '08',
      //       expirationYear: '2027',
      //       token: 'XYJrm3nHckpgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     },
      //     {
      //       id: '023918',
      //       available: true,
      //       number: '515590******2306',
      //       name: 'FELIPE M GONCALVES',
      //       cpf: '21335546880',
      //       service: 'mastercard',
      //       expirationMonth: '08',
      //       expirationYear: '2027',
      //       token: '10/j6ptAeVFgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     },
      //     {
      //       id: '023964',
      //       available: true,
      //       number: '522688******8086',
      //       name: 'PAULO ALBUQUERQUE',
      //       cpf: '21335546880',
      //       service: 'mastercard',
      //       expirationMonth: '03',
      //       expirationYear: '2027',
      //       token: 'zH9NUI/ORpBgJR4AQc9sZQ==',
      //       type: 'DEBT'
      //     }
      //   ],
      //   detail: {
      //     items: [{ id: 1, title: 'MENSALIDADE', value: 0.1 }],
      //     client: 'PABLO',
      //     amount: 0.1
      //   },
      //   pix: {
      //     status: 'SUCCESS',
      //     qrcode:
      //       'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAACx0lEQVR4Xu2WUW4cIRAF4SJw/1vkKHARcFWj7E42kuWPTOdn8GrlYcrSc/frB2V/v36Vz52P9QBnPcBZD3DWz4BRSp1zldaXP6Xv1aebicDks1ofrdXR6vL9qG5mAjwOFLGP2r3qXs2dbGCqs1q0Vro1+x/AaqVQKFWO1s9mJsBHXds9XlCpGZuJwDHtx/rb1bcCLsxCr7DN1LHLd7GygFGwhxOziqsyRM7Ru1kJQEhaVZ8AOsQlvnsiMDpIbMgsOrec3ZEJrG5isMnMWCUTDddapzQgHhDmL1GlFgKDyAJiTEcPiaVqnWjce3gzAF4yMqs6PQZZA8PIvsoCqMhqLkqEuBmpvg6RBTg0fYbOEq3azjOfREBnIGx6yUDaMkLxD3+QB+zIjXAtEW7FPN4dnjzA17jF+uCYOqNR3rkSgaVnJ/3aOocqScTo5AFDSWIeaN0EJcXGRWQCEBYxwz3mTRAdS9cygSgRNbJT+3Rpxk4m4MxMCxU9w69IdSsRMLRsl2REBxbWPJlANAmL2LHixRvCru1EAMLI8mD/fcZ3W/YSmQHgGDYdFtrl8Oiddkn7+wGzQmF6xtN1G+MwVioNqGojTLVqTM0Mx7wKlQAQm1imskt5bJOWcZgTARNLt2rVY5tGmHrfywMcX6xSIsMrXRPxIRFYEVpmyAjnIBOR10reDwRSozvWh75Zu5jdNIBTDMuoEcdoF5rm75fJuh1ApM/FSDc2PGi967wGJwFgVFA0Qhu9Kjpl2bZMIEqDYRVoloVSoy0PGFYoggyGS68VG+HjPMA1kDW95FAv7Uqrroa5HVAQDfPaj9T4juP1/V/cD6jJhrXjlwOy1TOBgSr9UqNHGIgQc56TgRCFVFPdNw5RNoBj3bFn1cMdrrwMkwEE45cdI0kgdlg3D7BDER44FvtGlJAefw7OzcB36wHOeoCzHuCsfwB8AWNQK6H6nTgVAAAAAElFTkSuQmCC',
      //     link:
      //       '00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/e65d5462-7dfc-4ebd-91f6-f4892193e5d15204000053039865802BR5925PORTO SEGURO LOC DE VEIC 6009SAO PAULO62070503***6304BAF9',
      //     expire_date: '24/12/2020',
      //     expire_time: '14:26:44',
      //     message:
      //       'COBRANCA CRIADA COM SUCESSO                                                                         ',
      //     emv:
      //       '00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/e65d5462-7dfc-4ebd-91f6-f4892193e5d15204000053039865802BR5925PORTO SEGURO LOC DE VEIC 6009SAO PAULO62070503***6304BAF9'
      //   },
      //   projectInfo: {
      //     token: '72700978-0d10-4000-90C6-6400ea57a0c0',
      //     tenantid: '42,0001',
      //     clientid: '895c522d-de30-41f0-88e5-6d8e6e0d91cc',
      //     tokenCards: '61c0bb7e-d692-4000-8460-2e80db0e7de8'
      //   },
      //   hash: 'a4a6b74c-5a24-4000-8C60-93894f8be807'
      // }

      // const textHeader = `Olá ${data.detail.client}! Por favor, escolha a sua forma de pagamento`
      /**
       * END TEMP
       */

      dispatch(weblink.actions.dataWeblinkSuccess({ data, textHeader }))
    } catch (error) {
      dispatch(
        weblink.actions.dataWeblinkError({
          error: error?.response?.data?.message || error?.message,
          config: error?.response?.data?.config || error?.config,
          textHeader: 'Este link já foi utilizado.'
        })
      )
    }
  }
}

// interface IDataWeblinkIsPayed {
//   uid: any
// }
export function isPayed(uid: string, cancelToken?: any) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => any
  ) => {
    const newCounter = getState().weblink.counter + 1

    try {
      // dispatch(weblink.actions.dataWeblinkFetch());
      const { card: payload } = getState().weblink

      console.log('uid', uid)
      console.log('payload', payload)

      const { data } = await isWeblinkPayed(uid, payload, cancelToken)

      // if (newCounter >= 300) {
      //   dispatch(
      //     weblink.actions.dataWeblinkIsPayed({
      //       isPayed: false,
      //     })
      //   );

      //   dispatch(weblink.actions.toggleErrorModal(true));

      //   return;
      // }

      if (data.challengelink) {
        dispatch(
          weblink.actions.dataWeblinkIsPayed({
            isPayed: false,
            counter: newCounter
          })
        )
      } else {
        dispatch(
          weblink.actions.dataWeblinkIsPayed({
            isPayed:
              data.status === 'SUCCESS' || data.debtstatus === 'APPROVED'
                ? true
                : false
          })
        )

        if (data.status === 'SUCCESS' || data.debtstatus === 'APPROVED') {
          dispatch(weblink.actions.toggleSuccessModal(true))
        } else {
          dispatch(weblink.actions.toggleErrorModal(true))
        }
      }
    } catch (error) {
      console.log('error', error)
      console.log('axios.isCancel(error):::', axios.isCancel(error))
      if (!axios.isCancel(error)) {
        dispatch(weblink.actions.toggleErrorModal(true))
      } else {
        dispatch(
          weblink.actions.dataWeblinkIsPayed({
            isPayed: false,
            counter: newCounter
          })
        )
      }

      // dispatch(
      //   weblink.actions.dataWeblinkError({
      //     error: error?.response?.data?.message || error?.message,
      //     config: error?.response?.data?.config || error?.config,
      //   })
      // );
    }
  }
}

interface IPaymentWeblink {
  date: string
  name: string
  doc: string
  installment?: string
  number: string
  cvc: string
}

export function PaymentWeblink(
  payment: IPaymentWeblink,
  cardType: 'credit' | 'debit'
) {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => any
  ) => {
    try {
      dispatch(weblink.actions.dataSendPayment())
      dispatch(weblink.actions.dataSendPayment())
      const [month, year] = payment.date.split('/')
      const name = payment.name
      const number = removeCharacters(payment.number)
      const document = removeCharacters(payment.doc)
      const cvc = payment.cvc
      const { data } = getState().weblink
      const device = getClientInfo()

      const payload = {
        hash: data?.hash,
        token: data?.projectInfo?.token,
        clientid: data?.projectInfo?.clientid,
        tenantid: data?.projectInfo?.tenantid,
        cardNumber: number,
        nameOnCard: name,
        expiryMonth: month,
        expiryYear: year,
        securityCode: cvc,
        document,
        device
      }

      dispatch(weblink.actions.dataPayment(payload))

      const response = await postPaymentWeblink(payload, cardType)

      if (response?.data.challengelink) {
        dispatch(weblink.actions.toggleChallengeModal(true))
        setTimeout(() => {
          window.open(response.data.challengelink, '_blank')
        }, 5000)
      }

      if (response?.data.status === 'SUCCESS') {
        dispatch(
          weblink.actions.toggleFeedback(
            cardType === 'credit' ? response.data.message : ''
          )
        )
        dispatch(weblink.actions.toggleSuccessModal(true))
      }
    } catch (error) {
      console.log('error', error)
      console.log('error data', error.data)
      console.log('error response', error.response)
      if (cardType === 'credit' && error?.response?.data?.message) {
        dispatch(weblink.actions.toggleFeedback(error?.response?.data?.message))
      }
      dispatch(weblink.actions.toggleErrorModal(true))
      dispatch(weblink.actions.dataSendPaymentError())
      dispatch(weblink.actions.setRecaptcha(''))
      throw error
    }
  }
}

export function closeAllModals() {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(weblink.actions.closeAllModal())
  }
}

export function getCards() {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(weblink.actions.dataWeblinkFetch())

      const erro = false

      const data = await new Promise((resolve, reject) => {
        setTimeout(() => {
          if (erro) {
            reject(new Error('Deu erro'))
          } else {
            resolve([
              {
                id: '1',
                type: 'disponivel',
                number: '1234432112344321',
                name: 'teste',
                cpf: '12312312312',
                expirationData: '22-12-2021',
                securitCod: '992'
              },
              {
                id: '2',
                type: 'disponivel',
                number: '1234432112344321',
                name: 'teste',
                cpf: '12312312312',
                expirationData: '22-12-2021',
                securitCod: '992'
              },
              {
                id: '3',
                type: 'indisponível',
                number: '1234432112344321',
                name: 'teste',
                cpf: '12312312312',
                expirationData: '22-12-2021',
                securitCod: '992'
              }
            ])
          }
        }, 20)
      })

      dispatch(weblink.actions.dataWeblinkSuccess({ data }))
    } catch (error) {
      dispatch(
        weblink.actions.dataWeblinkError({
          error: error?.response?.data?.message || error?.message,
          config: error?.response?.data?.config || error?.config
        })
      )
    }
  }
}

export const { reducer, actions } = weblink
