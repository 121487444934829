import axios from 'axios'
import config from 'config'

export const weblinkRequest = axios.CancelToken.source()

const { PROJECT, BASE_URL } = config.default

export const api = axios.create({
  baseURL: BASE_URL,
  timeout: 1000000000000000,
  headers: {
    'x-api-key': '22f13048-0778-45b5-8569-87309f40fe7b'
  }
})

interface IPostPaymentWeblink {
  hash: string
  token: string
  clientid: string
  tenantid: string
  cardNumber: string
  nameOnCard: string
  expiryMonth: string
  expiryYear: string
  securityCode?: string
  document?: string
}
export async function postPaymentWeblink(
  payload: IPostPaymentWeblink,
  cardType: 'credit' | 'debit'
) {
  if (cardType === 'debit') {
    return api.post('/debit/pay', payload)
  }

  if (cardType === 'credit') {
    return api.post('/credit/pay', payload)
  }
}

// interface IGetWeblink {
//     uid: string
// }
export async function getWeblink(uid: string) {
  return api.get(`/weblink/${uid}/${PROJECT}`)
}

// interface IGetWeblinkIsPayed {
//     uid: string
// }
export async function isWeblinkPayed(
  uid: string,
  payload: IPostPaymentWeblink,
  tokenCancel: any
) {
  const options = tokenCancel ? { cancelToken: tokenCancel } : {}

  return api.post(`/debit/get-information/${uid}/${PROJECT}`, payload, options)
}

export async function logs(clientId: string, hash: string) {
  return api.post('/log/pix', {
    clientId,
    hash
  })
}
