import styled from 'styled-components'

import { Container as Box } from 'components/Box/styles'
import CarPainel from 'assets/img/header/carPainel.png'

import { pxToRem } from 'utils'

export const Container = styled.header`
  background-color: ${({ theme }) => theme.colors.gray2};
  background: url(${CarPainel}) no-repeat 45% 10%;
  background-size: cover;
  opacity: 1;
  width: 100%;
  height: ${pxToRem(240)};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  position: relative;
  z-index: -1;

  margin-bottom: ${pxToRem(-45)};
`

export const Image = styled.img`
  height: ${pxToRem(35)};

  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.sm} {
    top: 18%;
  }
`

export const Title = styled.h1`
  font-weight: normal;
  font-size: ${pxToRem(22)};
  font-family: ${({ theme }) => theme.fontTypes.book};
  color: ${({ theme }) => theme.colors.white};

  margin-top: ${pxToRem(60)};

  position: relative;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.sm} {
    text-align: center;
    max-width: ${pxToRem(236)};
    margin-bottom: ${pxToRem(21)};
    margin-top: ${pxToRem(32)};
  }
`

export const Description = styled.div`
  font-size: ${pxToRem(18)};
  font-family: ${({ theme }) => theme.fontTypes.book};

  color: ${({ theme }) => theme.colors.primaryDark};
  opacity: 1;
  padding-bottom: ${pxToRem(16)};
`

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${pxToRem(6)};
`

export const TextLeft = styled.div`
  text-align: left;
  margin-right: ${pxToRem(16)};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: ${pxToRem(12)};
  font-family: ${({ theme }) => theme.fontTypes.bold};

  color: ${({ theme }) => theme.colors.darkGray};
  opacity: 1;
`

export const TextRight = styled.div`
  text-align: right;
  font-size: ${pxToRem(12)};
  font-family: ${({ theme }) => theme.fontTypes.bold};

  color: ${({ theme }) => theme.colors.primaryDark};
  opacity: 1;
`

export const Total = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  margin-top: ${pxToRem(20)};

  ${TextLeft} {
    font-size: ${pxToRem(10)};
    margin-right: 0;
    text-align: right;

    color: ${({ theme }) => theme.colors.darkGray};
  }

  ${TextRight} {
    font-size: ${pxToRem(15)};
    margin-top: ${pxToRem(5)};
  }
`

export const AlignBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: ${pxToRem(20)}; */

  ${Box} {
    width: 100%;
    max-width: ${pxToRem(635)};
    padding: ${pxToRem(20)};
    box-shadow: 0 0 6px ${({ theme }) => theme.colors.black2};
    border-radius: ${pxToRem(10)};
    @media ${({ theme }) => theme.mediaQueries.sm} {
      padding: ${pxToRem(20)} ${pxToRem(14)};
    }
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 ${pxToRem(15)};
  }
`
