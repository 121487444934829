import React, { useState, useCallback } from 'react'
import { Row, Col } from 'react-grid-system'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers'
import { ic_done } from 'react-icons-kit/md/ic_done'
import Cards, { Focused } from 'react-credit-cards'

import { PaymentWeblink } from 'store/reducers/weblink'
import { IStore } from 'store'

import { ICardFormProps } from './interfaces'

import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg'

import { Form, Input, Button } from 'components'

import {
  Container,
  CardGrid,
  Line,
  FormGrid,
  AlignGrids,
  Warning
} from './styles'

import { schema, IFormInputs } from './schema'

const Debit: React.FC<ICardFormProps> = ({
  cardType
  // submitFeedback
}) => {
  const { loadingPost } = useSelector((state: IStore) => state?.weblink)
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [cpf, setCpf] = useState('')
  const [date, setDate] = useState('')
  const [cvc, setCvc] = useState('')
  const [focus, setFocus] = useState<Focused>('name')

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  })

  const onSubmit = useCallback(
    (data: IFormInputs) => {
      dispatch(PaymentWeblink(data, cardType))
    },
    [cardType, dispatch]
  )

  return (
    <Container>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <AlignGrids>
            <FormGrid>
              <Line>
                <Col sm={12} md={12}>
                  <Input
                    label="Número do Cartão"
                    name="number"
                    tabIndex={1}
                    error={methods?.errors?.number}
                    mask="9999 9999 9999 9999"
                    autoComplete="off"
                    value={number}
                    ref={methods?.register}
                    onChange={(e) => setNumber(e.target.value)}
                    handleFocus={(value) => setFocus(value)}
                  />
                </Col>
              </Line>

              <Line>
                <Col sm={12} md={12}>
                  <Input
                    label="Nome do Titular"
                    name="name"
                    tabIndex={2}
                    error={methods?.errors?.name}
                    mask=""
                    autoComplete="off"
                    value={name}
                    ref={methods?.register}
                    onChange={(e) => setName(e.target.value)}
                    handleFocus={(value) => setFocus(value)}
                    autoFocus
                  />
                </Col>
              </Line>

              <Line dual>
                <Input
                  label="Data de Validade"
                  name="date"
                  tabIndex={3}
                  error={methods?.errors?.date}
                  mask="99/99"
                  autoComplete="off"
                  value={date}
                  ref={methods?.register}
                  onChange={(e) => setDate(e.target.value)}
                  handleFocus={(value) => setFocus(value)}
                />
                <Input
                  label="Cód Segurança"
                  name="cvc"
                  tabIndex={4}
                  error={methods?.errors?.cvc}
                  mask="9999"
                  autoComplete="off"
                  value={cvc}
                  ref={methods?.register}
                  onChange={(e) => setCvc(e.target.value)}
                  handleFocus={(value) => setFocus(value)}
                />
              </Line>

              <Line>
                <Col sm={12} md={12}>
                  <Input
                    label="CPF do Titular"
                    name="doc"
                    tabIndex={5}
                    error={methods?.errors?.doc}
                    mask="999.999.999-99"
                    autoComplete="off"
                    value={cpf}
                    ref={methods?.register}
                    onChange={(e) => setCpf(e.target.value)}
                  />
                </Col>
              </Line>
            </FormGrid>

            <CardGrid>
              <Cards
                cvc={cvc}
                expiry={date}
                name={name}
                number={number}
                placeholders={{
                  name: 'Nome do titular'
                }}
                focused={focus}
              />
            </CardGrid>
          </AlignGrids>

          {cardType === 'debit' && (
            <Warning>
              <AlertIcon />
              <span>
                Este cartão será utilizado <u>apenas</u> para este pagamento.
              </span>
            </Warning>
          )}

          {cardType === 'credit' && (
            <>
              <br />
              <br />
            </>
          )}

          <Row justify="center">
            <Button
              disabled={loadingPost}
              loading={loadingPost}
              icon={ic_done}
              color="primaryLight"
              type="submit"
            >
              FINALIZAR PAGAMENTO
            </Button>
          </Row>
        </Form>
      </FormProvider>
    </Container>
  )
}

export default Debit
