import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { IModalProps, IModalContent } from './interfaces'

import Warning from 'assets/icons/warning.png'
import Check from 'assets/icons/check.svg'
import Deny from 'assets/icons/deny.svg'
import Line from 'assets/icons/line.svg'
import PopPup1 from 'assets/icons/popup1.png'
import PopPup2 from 'assets/icons/popup2.svg'

import { actions as weblinkActions } from 'store/reducers/weblink'

import { Modal } from 'components'

import {
  ModalAlign,
  ImageModal,
  TextModal,
  ContextModal,
  Arrow,
  Link,
  AlignImageText,
  GridImage,
  GridText,
  Sublinhado
} from './styles'

const currentModalStyle: IModalContent = {
  warning: {
    icon: Warning,
    text:
      'O Banco irá fazer confirmações de segurança em uma nova guia do seu navegador.'
  },
  success: {
    icon: Check,
    text: 'Pagamento efetuado com sucesso!'
  },
  error: {
    icon: Deny,
    text:
      'Transação não autorizada. Entre em contato com a sua operadora ou tente com outro cartão.'
  },
  informative: {
    icon: '',
    text: ''
  }
}

const PaymentFeedbackModal: React.FC<IModalProps> = ({
  currentModal,
  messageFeedback,
  toggleModal,
  toggleDebitDropdown,
  setTypeSelected,
  typeSelected
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Modal type={currentModal} onClose={toggleModal}>
      <ModalAlign info>
        <ImageModal src={currentModalStyle[currentModal].icon} />

        <TextModal black={currentModal === 'warning'}>
          {messageFeedback && currentModal !== 'warning'
            ? messageFeedback
            : currentModalStyle[currentModal].text}
        </TextModal>

        {currentModal === 'warning' && (
          <TextModal black={currentModal === 'warning'}>
            Permita o uso de Pop-ups em seu navegador.
          </TextModal>
        )}

        {currentModal === 'success' && !messageFeedback && (
          <ContextModal>
            <Arrow />
            <TextModal black>
              <span>
                Quer trocar o cartão para os próximos pagamentos? É só entrar em
                contato com o <a href="#!">Suporte Carro Fácil</a>
              </span>
            </TextModal>
          </ContextModal>
        )}

        {currentModal === 'informative' && (
          <TextModal info>
            Para ativar os pop-ups e concluir o seu pagamento siga os passos:
          </TextModal>
        )}

        {currentModal === 'success' && (
          <button
            className="exit"
            onClick={() => {
              dispatch(
                weblinkActions.getText({
                  message: 'A Porto Seguro agradece o seu pagamento.'
                })
              )
              history.push('/pagamento/feedback')
            }}
          >
            OK
          </button>
        )}

        {currentModal === 'error' && (
          <button onClick={toggleModal}>Tentar novamente</button>
        )}

        {currentModal === 'informative' && (
          <AlignImageText>
            <GridImage>
              <ImageModal info src={PopPup1} />
              <ImageModal info src={Line} />
              <ImageModal info src={PopPup2} />
            </GridImage>
            <GridText>
              <TextModal>
                No iPhone, iPad ou iPod touch, acesse Ajustes {'>'} Safari e
                desative Bloquear Pop-ups
              </TextModal>
              <TextModal>
                Marque <Sublinhado>Permitir Pop-ups</Sublinhado>
              </TextModal>
            </GridText>
          </AlignImageText>
        )}

        {currentModal === 'informative' && (
          <Link
            onClick={() => {
              toggleDebitDropdown()
              setTypeSelected(typeSelected === 1 ? '' : 1)
              toggleModal()
            }}
            href="#!"
          >
            JÁ DESATIVEI, PROSSEGUIR
          </Link>
        )}
      </ModalAlign>
    </Modal>
  )
}

export default PaymentFeedbackModal
