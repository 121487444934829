import React from 'react'

import { Dropdown } from 'components'
import { CardForm, PixForm } from 'pages/home/components'

import { useSelector } from 'react-redux'

import { logs } from 'services/api'
import {
  AlignChange,
  Legend,
  AlignRequirements,
  AcceptedCads,
  Flags,
  Ul,
  AlignFlags,
  Image,
  WrapCard
} from './styles'
import { ICard } from '../../interfaces'

import Mc from 'assets/icons/mc.svg'
import Visa from 'assets/icons/visa.svg'

interface ChoosePaymenteInterface {
  show?: boolean
  selectedCard?: ICard | null
  typeSelected?: string | number
  debitDropdownIsOpened?: boolean
  creditDropdownIsOpened?: boolean
  toggleDebitDropdown?: () => void
  setTypeSelected?: any
  handleSubmitFeedback?: any
  toggleCreditDropdown?: () => void
  togglePixDropdown?: () => void
  pixDropdownIsOpened?: boolean
  toggleModal?: () => void
  setCurrentModal?: any
  qrCode?: string
  link?: string
  pix?: any
}

interface IWebLink {
  weblink: {
    data: {
      hash: string
      projectInfo: {
        clientid: string
      }
      detail: {
        amount?: number | any
        client?: string
        items?: any
        mode?: any
      }
      pix: {
        qrcode: string
        link: string
        expiration_date: string
        expiration_time: string
      }
    }
  }
}

const CardDebit: React.FC<ChoosePaymenteInterface> = ({
  show,
  typeSelected,
  toggleDebitDropdown,
  setTypeSelected,
  debitDropdownIsOpened,
  handleSubmitFeedback,
  toggleModal,
  setCurrentModal
}) => (
  <WrapCard debit>
    {show && (
      <Dropdown
        title="Cartão de débito"
        handleChange={() => {
          toggleDebitDropdown()
          setTypeSelected(typeSelected === 1 ? '' : 1)
        }}
        isDebit
        toggleModal={toggleModal}
        setCurrentModal={setCurrentModal}
        isOpen={debitDropdownIsOpened}
        centered={typeSelected === 1 || typeSelected === 2}
      >
        <AlignRequirements>
          <AcceptedCads>
            Função débito disponível para os bancos:
            <Ul>
              <li>Bradesco</li>
              <li>Itaú</li>
              <li>Nubank</li>
              <li>Santander</li>
            </Ul>
          </AcceptedCads>
          <Flags>
            Bandeiras
            <AlignFlags>
              <Image src={Mc} />
              <Image src={Visa} />
            </AlignFlags>
          </Flags>
        </AlignRequirements>

        <CardForm
          cardType="debit"
          submitFeedback={(feedback) => handleSubmitFeedback(feedback)}
        />
      </Dropdown>
    )}
  </WrapCard>
)

const CardCredit: React.FC<ChoosePaymenteInterface> = ({
  show,
  typeSelected,
  toggleCreditDropdown,
  setTypeSelected,
  creditDropdownIsOpened,
  handleSubmitFeedback
}) => (
  <WrapCard>
    {show && (
      <Dropdown
        title="Cartão de crédito"
        handleChange={() => {
          toggleCreditDropdown()
          setTypeSelected(typeSelected === 2 ? '' : 2)
        }}
        isOpen={creditDropdownIsOpened}
        style={{ marginTop: 20 }}
        centered={typeSelected === 1 || typeSelected === 2}
      >
        <CardForm
          cardType="credit"
          submitFeedback={(feedback) => handleSubmitFeedback(feedback)}
        />
      </Dropdown>
    )}
  </WrapCard>
)

interface IPix extends ChoosePaymenteInterface {
  hash: string
  projectInfo: {
    clientid: string
  }
}

const Pix: React.FC<IPix> = ({
  show,
  setTypeSelected,
  typeSelected,
  togglePixDropdown,
  pixDropdownIsOpened,
  pix,
  qrCode,
  link,
  hash,
  projectInfo
}) => (
  <WrapCard>
    {show && (
      <Dropdown
        title=""
        isPix
        outColor
        handleChange={() => {
          logs(projectInfo?.clientid, hash)
          togglePixDropdown()
          if (typeSelected === 3) {
            setTypeSelected('')
          }
        }}
        isOpen={pixDropdownIsOpened}
        style={{ marginTop: 20 }}
        centered={typeSelected === 1 || typeSelected === 2}
      >
        <PixForm
          pix={pix}
          qrCode={qrCode}
          link={link}
          setTypeSelected={setTypeSelected}
          typeSelected={typeSelected}
        />
      </Dropdown>
    )}
  </WrapCard>
)

const ChoosePayment: React.FC<ChoosePaymenteInterface> = ({
  selectedCard,
  typeSelected,
  debitDropdownIsOpened,
  creditDropdownIsOpened,
  toggleDebitDropdown,
  setTypeSelected,
  handleSubmitFeedback,
  toggleCreditDropdown,
  togglePixDropdown,
  pixDropdownIsOpened,
  toggleModal,
  setCurrentModal
}) => {
  const { data } = useSelector((state: IWebLink) => state.weblink)
  const {
    detail: { mode }
  } = data
  return (
    <>
      {!selectedCard?.available && (
        <>
          {typeSelected === '' && (
            <AlignChange>
              <Legend>Adicionar forma de pagamento</Legend>
            </AlignChange>
          )}

          <CardDebit
            show={
              (mode?.includes('DEBT') && typeSelected === '') ||
              typeSelected === 1
            }
            typeSelected={typeSelected}
            toggleDebitDropdown={toggleDebitDropdown}
            setTypeSelected={setTypeSelected}
            debitDropdownIsOpened={debitDropdownIsOpened}
            handleSubmitFeedback={handleSubmitFeedback}
            toggleModal={toggleModal}
            setCurrentModal={setCurrentModal}
          />

          <CardCredit
            show={
              (mode?.includes('CREDIT') && typeSelected === '') ||
              typeSelected === 2
            }
            typeSelected={typeSelected}
            toggleCreditDropdown={toggleCreditDropdown}
            setTypeSelected={setTypeSelected}
            creditDropdownIsOpened={creditDropdownIsOpened}
            handleSubmitFeedback={handleSubmitFeedback}
          />

          {data?.pix && mode?.includes('PIX') && (
            <Pix
              show={typeSelected === '' || typeSelected === 3}
              typeSelected={typeSelected}
              setTypeSelected={setTypeSelected}
              togglePixDropdown={togglePixDropdown}
              pixDropdownIsOpened={pixDropdownIsOpened}
              qrCode={data?.pix?.qrcode}
              link={data?.pix?.link}
              pix={data?.pix}
              projectInfo={data?.projectInfo}
              hash={data?.hash}
            />
          )}
        </>
      )}
    </>
  )
}

export default ChoosePayment
