import React from 'react'

import { Icon } from 'react-icons-kit'
import { ic_clear } from 'react-icons-kit/md/ic_clear'

import Iprops from './styles'

import Warning from 'assets/icons/warning.png'

import { TooltipContainer, Container, Text, Link, ImageModal } from './styles'

interface IModalTypeContent {
  icon: string
}

export interface IModalContent {
  warning: IModalTypeContent
}

const TooltipWarning: React.FC<Iprops> = ({
  toggleModal,
  toggleTooltip,
  toggleSee,
  setCurrentModal
}) => {
  const currentIconStyle: IModalContent = {
    warning: {
      icon: Warning
    }
  }
  return (
    <TooltipContainer>
      <Container
        onClick={() => {
          toggleTooltip()
          toggleSee()
        }}
      >
        <Icon size={20} style={{ color: 'black' }} icon={ic_clear} />
      </Container>

      <ImageModal src={currentIconStyle.warning.icon} />

      <Text negrito>
        Está utilizando o Safari? Por favor Desative o bloqueador de pop-up
      </Text>

      <Text>
        Quer saber como?{' '}
        <Link
          href="javascript:;"
          onClick={() => {
            toggleSee()
            setCurrentModal('informative')
            toggleModal()
          }}
        >
          Nós te mostramos.
        </Link>
      </Text>
    </TooltipContainer>
  )
}

export default TooltipWarning
