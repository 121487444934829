import React from 'react'
import { useSelector } from 'react-redux'

import sourceLogo from 'assets/img/header/logoWhite.svg'
import { parseCurrency } from 'utils'

import { IDetails } from './interfaces'

import { Box } from 'components'

import {
  Container,
  Title,
  Description,
  Image,
  Line,
  TextLeft,
  TextRight,
  Total,
  AlignBox
} from './styles'

interface IProp {
  weblink: {
    textHeader: string
    loading: boolean
    error: any
    data: { detail: IDetails }
  }
}

const Header: React.FC = () => {
  const {
    textHeader,
    loading,
    error,
    data: { detail: details }
  } = useSelector((state: IProp) => state?.weblink)

  return (
    <>
      <Container>
        <Image src={sourceLogo} />

        <Title>{textHeader && textHeader}</Title>
      </Container>

      {loading ? (
        <></>
      ) : (
        !loading &&
        !error && (
          <AlignBox>
            <Box>
              <Description>Detalhe</Description>

              {details?.items.map((item) => (
                <Line key={item.id}>
                  <TextLeft>{item.title}</TextLeft>
                  <TextRight>{parseCurrency(item.value)}</TextRight>
                </Line>
              ))}

              <Total>
                <TextLeft>TOTAL</TextLeft>
                <TextRight>{parseCurrency(details?.amount || 0)}</TextRight>
              </Total>
            </Box>
          </AlignBox>
        )
      )}
    </>
  )
}

export default Header
