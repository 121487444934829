import styled, { css } from 'styled-components'

import Tooltip from 'components/Tooltip'

export const TooltipContainer = styled(Tooltip)`
  align-self: center;
  position: absolute;
  z-index: 10;

  opacity: 1;
  transition: opacity 0.4s;

  padding: 16px;

  margin-bottom: 26px;

  display: flex;
  align-items: center;
`

export const Text = styled.div<any>`
  text-align: center;
  font-family: ${({ theme }) => theme.fontTypes.bold};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  margin: 10px 0 15px;

  ${({ negrito }) =>
    negrito &&
    css`
      text-align: center;
      font-size: 15px;
      font-family: ${({ theme }) => theme.fontTypes.bold};
      letter-spacing: 0px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.black};
      opacity: 1;
      max-width: 308px;
    `}
`

export const Container = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    top: -30px;
    right: -5px;
    cursor: pointer;

    @media screen and (max-width: 370px) {
      top: -18px;
    }
  }
`

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontTypes.black};
`

export const ImageModal = styled.img<any>`
  margin-bottom: 23.5px;
  margin-top: -25px;
  width: 54px;
  height: 48px;
`

export default interface Iprops {
  setCurrentModal?: any
  toggleTooltip?: () => void
  toggleModal?: () => void
  toggleSee?: () => void
}
