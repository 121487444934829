import styled, { css } from 'styled-components'

import { Container as Button } from 'components/Button/styles'

export const Item = styled.div<Iprops>`
  padding: 15px 0px;

  display: flex;
  align-items: center;

  text-align: left;
  font: normal normal medium 16px/21px Circular Std;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
  opacity: 1;
  transition: 0.8s;

  svg {
    margin-right: 10px;
  }

  label {
    cursor: pointer;
  }

  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 1px solid gray;
    `}

  cursor: pointer;

  :hover {
    @media ${({ theme }) => theme.mediaQueries.smUp} {
      background-color: ${({ theme }) => theme.colors.black2};
      transition: 0.8s;
    }
  }
`

export const ContainerOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.8s;

  flex-direction: column;

  label {
    max-width: 291px;
    margin-top: 13px;
    text-align: center;
    font: normal normal medium 14px/19px Circular Std;
    font-size: 14px;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.darkGray};
    opacity: 1;
  }
`

export const QrCode = styled.img`
  /* width: 214px;
  height: 214px; */

  /* background: transparent url('img/frame.png') 0% 0% no-repeat padding-box; */
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.black2};
  border-radius: 23px;
  opacity: 1;
  margin-bottom: 22px;
`

export const LinkContainer = styled.div`
  width: 100%;
  max-width: 389px;
  background: ${({ theme }) => theme.colors.green2} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;

  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  margin-top: 10px;
  padding: 14px;

  cursor: pointer;

  textarea {
    cursor: pointer;
    resize: none;
    width: 100%;
    border: none;
    background-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    max-width: 316px;
  }
`

export const GridButtons = styled.div`
  margin-top: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  ${Button} {
    :first-of-type {
      margin-bottom: 15px;
    }

    @media ${({ theme }) => theme.mediaQueries.xs} {
      max-width: 277px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.xs} {
    margin-top: 20px;
  }
`

export const Container = styled.div`
  padding: 15px 28px;
  margin-top: -20px;

  @media ${({ theme }) => theme.mediaQueries.xs} {
    padding: 15px 0;
  }
`
export const Tooltip = styled.div<Iprops>`
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 53%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;

  ${({ showTooltip }) =>
    showTooltip &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  @media ${({ theme }) => theme.mediaQueries.xs} {
    bottom: 50%;
  }

  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`

export const Label = styled.label`
  margin-top: 15px;
  display: block;
  font-size: 11px;
`

export interface Iprops {
  onClick?: any
  borderTop?: boolean
  debit?: boolean
  showTooltip?: boolean
  text?: string
}
