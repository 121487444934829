import styled, { css } from 'styled-components'

import { Container as Box } from 'components/Box/styles'
import { Container as Button } from 'components/Button/styles'

import { pxToRem } from 'utils'

export const Container = styled.div`
  max-width: ${pxToRem(635)};
  padding: ${pxToRem(40)} ${pxToRem(20)} ${pxToRem(83)};

  margin: 0 auto;

  ${Box} {
    padding: ${pxToRem(50)};

    display: flex;
    justify-content: center;

    ${Button} {
      :not(:nth-last-of-type(1)) {
        margin-right: ${pxToRem(20)};
      }
    }
  }
`

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.fontPrimary};
    font-family: ${theme.fontTypes.bold};
  `}
  font-size: ${pxToRem(21)};
`
