import React from 'react'

import { Box } from 'components'

import { Container, Title } from './styles'

const NotFound: React.FC = () => {
  return (
    <Container>
      <Box>
        <Title>Oops, parece que esta página não existe</Title>
      </Box>
    </Container>
  )
}

export default NotFound
