import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import loadingSource from 'assets/img/loading.gif'

import { dataWeblink, closeAllModals, isPayed } from 'store/reducers/weblink'

import { ICard, CurrentModal, IProp } from './interfaces'

import { Box } from 'components'

import {
  PaymentFeedbackModal,
  LegendPayment,
  ChoosePayment
} from './components'

import { Container, Title } from './styles'

let interval: any
let cancelToken

const Home: React.FC = () => {
  const { hash } = useParams<{ hash: string }>()
  const dispatch = useDispatch()
  const {
    openChallengeModal,
    openSuccessModal,
    openErrorModal,
    loading,
    messageFeedback,
    error
  } = useSelector((state: IProp) => state?.weblink)
  const [typeSelected, setTypeSelected] = useState<any>('')
  const [selectedCard, setSelectedCard] = useState<ICard | null>(null)
  const [hideLink] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentModal, setCurrentModal] = useState<CurrentModal>('warning')
  const [debitDropdownIsOpened, setDebitDropdownIsOpened] = useState(false)
  const [creditDropdownIsOpened, setCreditDropdownIsOpened] = useState(false)
  const [pixDropdownIsOpened, setPixDropdownIsOpened] = useState(false)

  useEffect(() => {
    async function getDataWeblink() {
      dispatch(dataWeblink(hash))
    }

    getDataWeblink()
  }, [dispatch, hash])

  useEffect(() => {
    const watchPayment = async () => {
      if (openChallengeModal) {
        setCurrentModal('warning')

        dispatch(isPayed(hash))

        interval = setInterval(() => {
          if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel('Operation canceled due to new request.')
          }
          cancelToken = axios.CancelToken.source()

          setTimeout(() => {
            dispatch(isPayed(hash, cancelToken.token))
          }, 500)
        }, 40000)
      }

      if (openSuccessModal) {
        setCurrentModal('success')

        clearInterval(interval)
      }

      if (openErrorModal) {
        setCurrentModal('error')

        clearInterval(interval)
      }

      if (openChallengeModal || openSuccessModal || openErrorModal) {
        setShowModal(true)
      }
    }

    watchPayment()
  }, [openChallengeModal, openSuccessModal, openErrorModal, dispatch, hash])

  const toggleModal = useCallback(() => {
    dispatch(closeAllModals())
    setShowModal(!showModal)
  }, [dispatch, showModal])

  const toggleDebitDropdown = useCallback(() => {
    setDebitDropdownIsOpened(!debitDropdownIsOpened)
  }, [debitDropdownIsOpened])

  const toggleCreditDropdown = useCallback(() => {
    setCreditDropdownIsOpened(!creditDropdownIsOpened)
  }, [creditDropdownIsOpened])

  const togglePixDropdown = useCallback(() => {
    setPixDropdownIsOpened(!pixDropdownIsOpened)
  }, [pixDropdownIsOpened])

  const handleSubmitFeedback = useCallback(
    (success: boolean) => {
      if (success) {
        setCurrentModal('success')
      } else {
        setCurrentModal('error')
      }

      toggleModal()
    },
    [toggleModal]
  )

  if (loading) {
    return (
      <Box loading>
        <img src={loadingSource} alt="Imagem de Loading" />
      </Box>
    )
  }
  if (!!error) {
    return (
      <Box isShadow>
        <Title>{error}</Title>
      </Box>
    )
  }

  return (
    <Container>
      {showModal && (
        <PaymentFeedbackModal
          currentModal={currentModal}
          toggleModal={toggleModal}
          messageFeedback={messageFeedback}
          toggleDebitDropdown={toggleDebitDropdown}
          setTypeSelected={setTypeSelected}
          typeSelected={typeSelected}
        />
      )}

      <LegendPayment
        typeSelected={typeSelected}
        hideLink={hideLink}
        setTypeSelected={setTypeSelected}
        setDebitDropdownIsOpened={setDebitDropdownIsOpened}
        setCreditDropdownIsOpened={setCreditDropdownIsOpened}
        setPixDropdownIsOpened={setPixDropdownIsOpened}
        setSelectedCard={setSelectedCard}
      />

      <ChoosePayment
        selectedCard={selectedCard}
        typeSelected={typeSelected}
        debitDropdownIsOpened={debitDropdownIsOpened}
        creditDropdownIsOpened={creditDropdownIsOpened}
        pixDropdownIsOpened={pixDropdownIsOpened}
        togglePixDropdown={togglePixDropdown}
        toggleDebitDropdown={toggleDebitDropdown}
        setTypeSelected={setTypeSelected}
        handleSubmitFeedback={handleSubmitFeedback}
        toggleCreditDropdown={toggleCreditDropdown}
        toggleModal={toggleModal}
        setCurrentModal={setCurrentModal}
      />
    </Container>
  )
}

export default Home
