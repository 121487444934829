import React, { useState } from 'react'
import { Icon } from 'react-icons-kit'
import {
  ic_keyboard_arrow_down,
  ic_keyboard_arrow_up
} from 'react-icons-kit/md'

import { themeColors } from 'styles/theme'

import { IProp } from './interfaces'

import Visa from 'assets/icons/visa.svg'
import Mc from 'assets/icons/mc.svg'
import Pix from 'assets/icons/pix.png'

import { TooltipWarning } from './components'

import useWindowSize from 'hooks/useWindowSize'

import {
  Container,
  CardImage,
  Handler,
  IconWrapper,
  WrapperContent,
  Label,
  ContainerGrid,
  Image
} from './styles'

const Dropdown: React.FC<IProp> = ({
  title,
  handleChange,
  children,
  error,
  primary,
  isOpen,
  style,
  selectedCard,
  setCurrentModal,
  centered,
  outColor,
  isPix,
  toggleModal,
  isDebit
}) => {
  const size = useWindowSize().width

  const [tooltipeOpen, setTooltipOpen] = useState(false)
  const [alreadyBeenSeen, setAlreadyBeenSeen] = useState(false)

  const color = outColor ? themeColors.cian : themeColors.white

  function toggleSee() {
    setAlreadyBeenSeen(!alreadyBeenSeen)
  }

  function toggleTooltip() {
    setTooltipOpen(!tooltipeOpen)
  }

  return (
    <ContainerGrid style={style && style}>
      <Container error={error}>
        <Handler
          isPix={isPix}
          outColor={outColor}
          error={error}
          onClick={() => {
            if (isDebit && !alreadyBeenSeen && size <= 470) {
              toggleTooltip()
            } else {
              handleChange && handleChange()
            }
          }}
          isOpen={isOpen || primary}
          centered={centered}
        >
          {selectedCard && (
            <CardImage
              shouldIncrease={true}
              src={selectedCard.service === 'visa' ? Visa : Mc}
            />
          )}

          {isPix ? (
            <Image alt="Opção pix" src={Pix} />
          ) : (
            <>
              <span>{title !== '' ? title : ''}</span>
              {isDebit && tooltipeOpen && (
                <TooltipWarning
                  setCurrentModal={setCurrentModal}
                  toggleModal={toggleModal}
                  toggleTooltip={toggleTooltip}
                  toggleSee={toggleSee}
                />
              )}
            </>
          )}

          <IconWrapper>
            <Icon
              size={20}
              style={{ color: color }}
              icon={isOpen ? ic_keyboard_arrow_up : ic_keyboard_arrow_down}
            />
          </IconWrapper>
        </Handler>

        {isOpen && <WrapperContent isPix={isPix}>{children}</WrapperContent>}
      </Container>
      {error && <Label>Cartão Expirado - Tente outra forma de pagamento</Label>}
    </ContainerGrid>
  )
}

export default Dropdown
