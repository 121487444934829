import styled from 'styled-components'

import { pxToRem } from 'utils'
import { IModalProps } from './interfaces'

const backgroundColors = {
  warning: '#ffffff',
  success: '#00B987',
  error: '#FF0000',
  informative: '#0107D7'
}

export const Container = styled.div`
  width: 100%;
  height: 110%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div<any>`
  background-color: ${({ type }: IModalProps) => backgroundColors[type]};
  width: ${pxToRem(520)};

  border-radius: ${pxToRem(20)};

  strong {
    font-size: ${pxToRem(20)};
  }

  svg {
    right: calc(-100% + ${pxToRem(35)});
    position: relative;
    top: ${pxToRem(8)};
    cursor: pointer;
  }

  @media ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
    height: 110%;
    border-radius: 0;
  }
`

export const AlignContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${pxToRem(18)};
  letter-spacing: 0;

  @media ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    height: 100%;
  }
`
