import styled from 'styled-components'

import { pxToRem } from 'utils'

export const Container = styled.form`
  background: ${({ theme }) => theme.colors.white};

  padding: 0 ${pxToRem(53)};

  position: relative;

  @media screen and (max-width: 768px) {
    padding: 0 ${pxToRem(13)};
  }
`

export const Title = styled.h1`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.fontPrimary};
  font-size: ${pxToRem(17)};

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${pxToRem(15)};
`

export const Wrap = styled.div``
