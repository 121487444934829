import * as yup from 'yup'
import { isCpf } from '@pixter/utils/lib/validators'
import { fns as payment } from 'payment'

export interface IFormInputs {
  number: string
  name: string
  date: string
  cvc: string
  doc: string
}

export const schema = yup.object().shape({
  number: yup.string().required('Campo obrigatório'),
  name: yup.string().required('Campo obrigatório'),
  date: yup
    .string()
    .min(5, 'Informe MM/AA')
    .test('teste-date', 'Data inválida', (value) => {
      if (value) {
        const [month, year] = value.split('/')
        return payment.validateCardExpiry(month, year)
      }

      return false
    })
    .required('Data de validade inválida'),
  cvc: yup
    .string()
    .min(3, `CVV inválido`)
    .max(4, `CVV inválido`)
    .test('test-cvc', 'CVV inválido', (value) => {
      if (value) {
        return payment.validateCardCVC(value)
      }

      return false
    })
    .required('CVV inválido'),
  doc: yup
    .string()
    .test('teste-doc', 'CPF inválido', (value) => isCpf(value))
    .required('Campo obrigatório')
})
