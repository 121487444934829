import React from 'react'
import { themeColors } from 'styles/theme'

import { ReactComponent as mc } from 'assets/icons/mc.svg'

export const dict = {
  mc
}

interface IProps {
  name: keyof typeof dict
  color?: keyof typeof themeColors
}

// eslint-disable-next-line react/display-name
export default ({
  name,
  color,
  fill,
  ...rest
}: IProps & React.SVGAttributes<SVGElement>) => {
  const Icon = dict[name]
  return Icon ? (
    <Icon fill={color ? themeColors[color] : fill} {...rest} />
  ) : null
}
