import styled, { css } from 'styled-components'
import { pxToRem } from 'utils'

export const AlignChange = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${pxToRem(20)};

  ${({ somethingOpen }: any) =>
    somethingOpen &&
    css`
      margin-bottom: ${pxToRem(40)};
    `}

  ${({ shoulShrink }: any) =>
    shoulShrink &&
    css`
      margin-bottom: ${pxToRem(28)};
    `}

  :last-of-type {
    margin-top: ${pxToRem(45)};
  }

  a {
    color: ${({ theme }) => theme.colors.primaryLight};
  }
`

export const Legend = styled.div<any>`
  text-align: center;
  font-family: ${({ theme }) => theme.fontTypes.book};
  font-size: ${pxToRem(18)};
  color: ${({ theme }) => theme.colors.darkGray};
  opacity: 1;

  ${({ top }: any) =>
    top &&
    css`
      margin-top: ${pxToRem(40)};
    `}
`

export const Flags = styled.div`
  display: flex;
  flex-direction: column;
`
