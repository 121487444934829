import styled, { css } from 'styled-components'

export interface ILoading {
  loading?: boolean
  isShadow?: boolean
}

export const Container = styled.div<ILoading>`
  background: ${({ theme }) => theme.colors.white};

  ${({ loading }) =>
    loading &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  ${({ isShadow }) =>
    isShadow &&
    css`
      max-width: 500px;
      margin: 0 auto;
      border-radius: 3px;
      padding: 15px;
      text-align: center;
      box-shadow: 1px 1px 5px 6px rgb(0 0 0 / 0.09);
    `};
`
